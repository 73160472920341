<template>
  <div class="clientes">
    <h1 class="left">{{ ready ? 'Clientes de ' + company.name : '&nbsp;' }}</h1>

    <div class="box left" style="padding:20px">
      <h3>Enviar a una persona</h3>
      <vs-row vs-type="flex" vs-align="flex-end" vs-justify="space-between" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="7">
          <vs-input type="text" v-model="newClient.name" class="inputx" placeholder="Nombre" />&nbsp;&nbsp;&nbsp;
          <vs-input type="text" v-model="newClient.lastName" class="inputx" placeholder="Apellido" />&nbsp;&nbsp;&nbsp;
          <vs-input
            type="text"
            v-model="newClient.email"
            class="inputx"
            placeholder="Email"
            icon=".."
            icon-pack="fab fa-google"
          />&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;
          <vs-button @click="create(newClient)">Enviar</vs-button>
        </vs-col>
      </vs-row>
    </div>

    <div class="box left" style="padding:20px" v-if="sendingMass">
      <h2>Realizando envío a {{ massTotal }} direcciones. Quedan: {{ massResting }}</h2>
      <div class="progressContainer">
        <div class="progressBar" :style="'width:' + massPercent + '%'"></div>
        <div class="progress">{{ massPercent }}%</div>
      </div>
    </div>

    <div class="box left" style="padding:20px" v-if="!sendingMass">
      <h3>Envío masivo</h3>
      <label
        style="font-size:14px;display:block"
      >Adjuntar un archivo CSV con el formato "Nombre,Apellido,Email"</label>
      <br />
      <input type="file" ref="file" @change="createFromCSV($event)" style="display: none" />

      <!-- <vs-input type="fevent, ile" v-model="newClient.csv" class="inputx" style="display:inline-block"/> -->
      <vs-button @click="$refs.file.click()">Seleccionar CSV</vs-button>
    </div>

    <br />
    <br />
    <div class="generic-code">
      Link genérico: &nbsp;
      <vs-input
        disabled
        icon=".."
        icon-pack="far fa-copy"
        icon-after="true"
        style="width:250px; display:inline-block"
        @icon-click="copyLink"
        @on-click="copyLink"
        v-model="link"
        laxbel-placeholder="Link genérico"
      />
    </div>

    <br />
    <br />
    <p>Mostrando clientes {{ (currentPage - 1) * perPage + 1 }}-{{ Math.min(currentPage * perPage, totalClients) }} de {{ totalClients }}</p>
    <br />
    <table-render
      :rows="rows"
      :schema="schema"
      :actions="actions"
      v-if="ready"
      no-auto-resize="true"
    />
    <br />
    <div class="center">
      <vs-pagination
        :total="totalPages"
        :value="currentPage"
        @input="changePage"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import {
  db, query, addDoc, doc, collection, deleteDoc, getDoc, getDocs, setDoc,
  onSnapshot, httpsCallable, functions, where, orderBy, startAfter, limit
} from '../firebase'
import TableRender from './Rage/TableRender.vue';
import moment from 'moment';

export default {
  name: 'Admin',
  components: { TableRender },
  data() {
    return {
      templateId: 'd-c9343be9b3914405bd9c3b7b2f1d6b64',
      clearSnapshot: null,
      clients: [],
      totalClients: 0,
      perPage: 10,
      currentPage: 1,
      lastVisible: null,
      link: '',
      params: {},
      rows: [],
      sendingMass: false,
      massTotal: 0,
      massResting: 0,
      massPercent: 0,
      schema: [],
      ready: false,
      newClient: {
        name: '',
        email: '',
      }
    }
  },
  async mounted() {
    this.company = (await getDoc(doc(db, "clients", this.$store.state.user.currentTenant))).data();
    this.companyId = (await getDoc(doc(db, "clients", this.$store.state.user.currentTenant))).id;
    this.link = `${window.location.origin}/${this.company.uri}`;

    this.schema = [
      { label: 'Nombre', visible: true, key: 'fullname', width: 300 },
      { label: 'Email', visible: true, key: 'email', width: 300 },
      { label: 'Fecha de envío', visible: true, key: 'created', width: 300 },
      { label: 'Ops', visible: true, key: 'actions', width: 200 },
    ];

    const paramsRef = doc(collection(db, 'config'), 'params');
    onSnapshot(paramsRef, async (params) => {
      if (params.data()) this.params = params.data();
      this.ready = false;

      this.actions = [
        { action: id => this.copyLink(id), icon: 'copy', color: 'gray' },
        { action: id => this.sendMail(id), icon: 'paper-plane', color: 'gray' },
        { ...this.params.removeCustomers ? { action: id => this.del(id), icon: 'trash', color: 'darkred' } : {} },
      ];

      await this.fetchTotalClients();
      await this.fetchPage();
    });
  },
  methods: {
    async fetchTotalClients() {
      const snapshot = await getDocs(query(collection(db, "clients", this.companyId, 'customers')));
      this.totalClients = snapshot.size;
    },
    async fetchPage() {
      this.ready = false;
      let q = query(
        collection(db, "clients", this.companyId, 'customers'),
        orderBy('createdAt', 'desc'),
        limit(this.perPage)
      );

      if (this.lastVisible && this.currentPage > 1) {
        q = query(q, startAfter(this.lastVisible));
      }

      const snapshot = await getDocs(q);

      this.rows = snapshot.docs.map(doc => ({
        id: doc.id,
        created: moment(doc.data().createdAt.toDate()).format('DD/MM/YYYY, hh:mm'),
        ...doc.data(),
        fullname: `${doc.data().name} ${doc.data().lastName}`,
      }));

      this.lastVisible = snapshot.docs[snapshot.docs.length - 1];
      this.ready = true;
    },
    async changePage(page) {
      if (page === this.currentPage) return;

      if (page < this.currentPage) {
        // Going backwards, reset pagination
        this.currentPage = 1;
        this.lastVisible = null;
        await this.fetchPage();

        // Now move forward to the desired page
        for (let i = 1; i < page; i++) {
          await this.fetchPage();
          this.currentPage++;
        }
      } else {
        // Going forwards
        await this.fetchPage();
        this.currentPage = page;
      }
    },
    totalShowing() {
      return this.perPage * this.page <= this.docs.length ? this.perPage * this.page : this.docs.length
    },
    loadPage() {
      this.rows = []
      this.ready = false;
      this.docs.forEach((doc, i) => {
        if (i >= this.perPage * this.page - this.perPage && i < this.page * this.perPage) {
          this.rows.push({
            id: doc.id,
            created: moment(doc.data().createdAt.toDate()).format('DD/MM/YYYY, hh:mm'),
            ...doc.data(),
            fullname: `${doc.data().name} ${doc.data().lastName}`,
          });
          this.$nextTick(_ => this.ready = true)
        }
      });
    },
    copyLink(id) {
      console.log(`es`, id);
      const el = document.createElement('textarea');
      el.value = !id ? this.link : this.link + '/' + id;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$vs.notify({ title: 'Link copiado', text: 'Ahora puedes pegarlo donde quieras', color: 'primary', position: 'top-center', time: 3000 });
    },
    async sendMail(id) {
      const client = this.rows.find(c => c.id === id);
      if (!confirm(`¿Estás seguro de enviar un correo a ${client.name} ${client.lastName}?`)) return
      const templateData = {
        // highRateLink: this.highRateLink(), 
        rateLink: this.link + '/' + id,
        name: client.name,
        logo: this.company.logo,
        company: this.company.name,
        background_color: this.company.backgroundColor
      };
      console.log(`enviaré`, { to: client.email, ...templateData });

      httpsCallable(functions, 'mail')({ action: 'sendEmail', to: client.email, templateId: this.templateId, templateData }).then(res => {
        alert('Correo enviado correctamente');
        setTimeout(_ => location.reload(), 2000);
      }).catch(err => {
        alert('Error al enviar el correo. Contáctanos para ayudarte.');
      })
    },
    async create() {
      const newClient = {
        name: this.newClient.name,
        lastName: this.newClient.lastName,
        email: this.newClient.email,
        // phone: this.newClient.phone,
        createdAt: new Date(),
      }
      addDoc(collection(db, "clients", this.$store.state.user.currentTenant, 'customers'), newClient).then(res => {
        //armar template del email con datos del cliente
        const templateData = {
          // highRateLink: this.highRateLink(), 
          rateLink: this.link + '/' + res.id,
          name: newClient.name,
          logo: this.company.logo,
          company: this.company.name,
          background_color: this.company.backgroundColor
        }
        //enviar correo
        httpsCallable(functions, 'mail')({ action: 'sendEmail', to: newClient.email, templateId: this.templateId, templateData }).then(res => {
          alert('Correo enviado correctamente');
          setTimeout(_ => location.reload(), 2000);
        }).catch(err => {
          alert('Error al enviar el correo. Contáctanos para ayudarte.');
        })
        //resetear formulario
        this.newClient = {};
      })
    },
    async createFromCSV(event) {
      const file = event.target.files[0];
      console.log(`mamila`, file);
      if (!file) return alert('No se seleccionó ningún archivo');
      const reader = new FileReader();
      const docPromises = [];
      const mailingPromises = [];
      this.sendingMass = true;
      this.massPercent = 0;
      this.massTotal = 0;
      this.massResting = 0;
      let sent = 0;
      reader.onload = async (e) => {
        const data = e.target.result;
        const rows = data.split('\n');
        this.massTotal = rows.length;
        this.massResting = rows.length;
        event.target.value = ''; //resetear el input
        if (rows.length > 299) return alert('No puedes enviar más de 300 correos por día');
        for (let i = 0; i < rows.length; i++) {
          const row = rows[i].split(rows[i].match(/;/g) && rows[i].match(/;/g).length > 1 ? ';' : ',');
          const newClient = {
            name: row[0],
            lastName: row[1],
            email: row[2],
            // phone: row[3],
            createdAt: new Date(),
          }

          //cancelar si la linea del archivo viene vacía
          if (!newClient.email) continue;

          //cancelar si el usuario ya existe
          const clientQuery = query(collection(db, "clients", this.$store.state.user.currentTenant, 'customers'), where('email', '==', newClient.email));
          const client = (await getDocs(clientQuery)).docs[0];
          if (client) continue;

          //añadir a todos los rows a la lista de customers en firestore
          docPromises.push(addDoc(collection(db, "clients", this.$store.state.user.currentTenant, 'customers'), newClient).then(res => {
            //al tener su ID, enviarle email y añadirlo a la lista de promesas de emails
            //👁️👁️ aqui se hace el envío masivo
            mailingPromises.push(httpsCallable(functions, 'mail')({
              action: 'sendEmail',
              to: newClient.email,
              templateId: this.templateId,
              templateData: { name: newClient.name, logo: this.company.logo, rateLink: this.link + '/' + res.id, company: this.company.name, background_color: this.company.backgroundColor }
            }));
            this.massResting--;
            this.massPercent = Math.round(((this.massTotal - this.massResting) / this.massTotal) * 100);

            sent++;
          }).catch(err => {
            alert(`Error enviando a ` + newClient.name + `: ` + err);
            setTimeout(_ => location.reload(), 2000);
          }));
        }
        console.log(`Esperando que se agreguen`,);
        Promise.all(docPromises)
          .catch(err => { this.sendingMass = false; alert(`Error al agregar: ` + err); setTimeout(_ => location.reload(), 2000); })
          .then(_ => {
            console.log(`todos agregados`,);
          });
        Promise.all(mailingPromises)
          .catch(err => { this.sendingMass = false; alert(`Error al enviar: ` + err); setTimeout(_ => location.reload(), 2000); })
          .then(_ => {
            console.log(`todos enviados`,);
            this.sendingMass = false;
            setTimeout(_ => alert(sent + ' correos enviados correctamente.'), 1000);
            setTimeout(_ => location.reload(), 2000);
          });
        this.newClient = {};
      }
      reader.readAsText(file);
    },
    go(uri) {
      location = '/' + uri
    },
    add() {
      this.clients.unshift({ name: '', uri: '', id: '' + Math.random() })
    },
    del(client) {
      if (!confirm('¿borrar?')) return
      console.log(`reborro`, client);
      // this.clients.splice(this.clients.indexOf(client), 1)
      deleteDoc(doc(collection(db, "clients", this.$store.state.user.currentTenant, 'customers'), client));
    },
    save(client) {
      console.log(`salvo`, client.id);
      setDoc(doc(db, "clients", client.id), client)
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalClients / this.perPage);
    },
    user() {
      return this.$store.state.user
    }
  },
  watch: {
  }
}
</script>

<style lang="scss">
.center .vs-pagination--mb {
  display: block !important;
}

.progressContainer {
  margin-top: 20px;
  width: 600px;
  border: 1px solid black;
  height: 30px;
  background-color: #b196f7;
  position: relative;
}

.progressBar {
  width: 0%;
  height: 100%;
  background: #724bd4;
}

.progress {
  position: absolute;
  width: 100%;
  top: 5px;
  color: white;
  text-align: center;
  font-weight: bold;
}

.clientes .generic-code {
  width: 100%;
  border: 2px dashed rgb(185, 185, 185);
  padding: 12px;
  border-radius: 5px;
  background: rgb(230, 229, 229);

  .vs-input--input {
    background: white;
  }
}

.clientes .vs-input--icon {
  cursor: pointer;

  &:hover {
    color: rgba(var(--vs-primary), 1) !important;
  }
}
</style>